import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  actionName: string;
  buttonName: string;
}

@Component({
  selector: 'app-alert-dialog',
  standalone: true,
  imports: [MatButtonModule, CommonModule],
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  @Output() decision = new EventEmitter<boolean>();
  title: string | undefined;
  actionName: string | undefined;
  buttonName: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.actionName = this.data.actionName;
    this.buttonName = this.data.buttonName;
  }

  onCancel() {
    this.dialogRef.close();
    this.decision.emit(false);
  }

  onDelete() {
    this.dialogRef.close();
    this.decision.emit(true);
  }
}
