import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';

import {CoreService} from 'src/app/services/core/core.service';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: CoreService) {}

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.user$.value?.isAdmin) {
      return true;
    }
    return this.router.parseUrl('/');
  }

  canLoad(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _route: Route,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _segments: UrlSegment[],
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.user$.value?.isAdmin) {
      return true;
    }
    return this.router.parseUrl('/');
  }
}
