<app-header (menuToggled)="toggle()" class="sticky-top"></app-header>
<mat-drawer-container>
  <mat-drawer mode="side" [opened]="opened" class="sidebar_bg">
    <app-sidebar (menuToggled)="toggleMobile()"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content (click)="hideSidebar()">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
