import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';

import {User} from 'src/app/interfaces/user.interface';
import {CoreService} from 'src/app/services/core/core.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements AfterViewInit {
  @Output() menuToggled = new EventEmitter<boolean>();
  activeIndex = 0;
  user!: User;
  panelOpenState = false;
  disableAnimation = true;
  constructor(private authService: CoreService) {
    this.user = this.authService.user$.value as User;
  }

  ngAfterViewInit() {
    setTimeout(() => (this.disableAnimation = false));
  }
}
