<div class="py-5 px-3">
  <h1 mat-dialog-title *ngIf="message && isArray(message)">
    {{ statusText || 'Error Message' }}
    <span class="material-symbols-outlined icon-error red_text"> error </span>
  </h1>
  <div *ngIf="message && isArray(message)">
    <ul class="ps-3">
      <li *ngFor="let error of message">
        {{ error }}
      </li>
    </ul>
  </div>
  <div *ngIf="message && isString(message)">
    <h1 mat-dialog-title>
      {{ message }}
      <span class="material-symbols-outlined icon-error red_text"> error </span>
    </h1>
  </div>
  <div mat-dialog-actions class="mt-4 ws-100">
    <button
      (click)="onCancel()"
      mat-raised-button
      color="primary"
      class="w-100"
      cdkFocusInitial
    >
      OK
    </button>
  </div>
</div>
