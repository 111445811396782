import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

export interface DialogData {
  message: string;
  statusText: string;
}

@Component({
  selector: 'app-error-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  @Output() decision = new EventEmitter<boolean>();
  message: string | undefined;
  statusText: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    this.message = this.data.message;
    this.statusText = this.data.statusText;
  }

  onCancel() {
    this.dialogRef.close();
    this.decision.emit(false);
  }

  isArray(value: string[] | string): boolean {
    return Array.isArray(value);
  }

  isString(value: string): boolean {
    return typeof value === 'string';
  }
}
