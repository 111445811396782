<mat-toolbar color="primary">
  <button mat-icon-button (click)="menuToggled.emit(true)">
    <span class="material-symbols-outlined"> menu </span></button
  ><span class="title" routerLink="/">
    <span class="material-symbols-outlined"> home </span></span
  >
  <span class="spacer"></span>
  <span class="welcome-text me-2" *ngIf="user"
    ><span class="d-none d-lg-inline-block text-capitalize"
      >Hi {{ user.name || 'User' }}
    </span>
    <small class="d-block text-capitalize"
      ><span class="text-uppercase"
        ><b class="level">{{ user.level }}</b></span
      >
      {{ user.role }}</small
    ></span
  >
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <span class="material-symbols-outlined"> manage_accounts </span>
  </button>
  <mat-menu #menu="matMenu" class="user-dropdown">
    <button mat-menu-item (click)="alertDialog()">
      <span class="material-symbols-outlined align-middle primary-color me-2">
        logout
      </span>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
