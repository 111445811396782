import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss'],
})
export class StandardLayoutComponent implements OnInit {
  opened = true;
  toggle(): void {
    this.opened = !this.opened;
  }
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
  ) {}
  ngOnInit() {
    if (this.router.url === '/change-password') {
      this.opened = false;
    }
    this.closeSidebarOnChangePassword();
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.opened = false;
        }
      });
    // changes
  }
  closeSidebarOnChangePassword() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/change-password') {
          this.opened = false;
        } else {
          this.opened = true;
        }
      }
    });
  }

  hideSidebar() {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.opened = false;
        }
      });
  }

  toggleMobile(): void {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.opened = !this.opened;
        }
      });
  }
}
