import {ChangeDetectorRef, Component} from '@angular/core';

import {CoreService} from './services/core/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Director General Telecom';
  accessToken: string | null;
  constructor(
    private authService: CoreService,
    private cdr: ChangeDetectorRef,
  ) {
    this.accessToken = sessionStorage.getItem('accessToken');
    if (this.accessToken) {
      this.cdr.detach();
      this.authService
        .whoAmI()
        .subscribe({complete: () => this.cdr.reattach()});
    }
  }
}
