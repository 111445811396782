<div class="p-3">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <p class="mb-4">{{ actionName }}</p>
  </div>
  <div mat-dialog-actions class="mt-3">
    <button
      type="button"
      class="me-3"
      mat-stroked-button
      color="primary"
      (click)="onCancel()"
    >
      Cancel
    </button>
    <button
      type="submit"
      (click)="onDelete()"
      mat-raised-button
      color="primary"
      cdkFocusInitial
    >
      {{ buttonName }}
    </button>
  </div>
</div>
